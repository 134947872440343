<template>
    <section id="proyectos" class="proyectos">
        <h2>Proyectos</h2>
        <div class="miniLine"></div>

        <div    
        data-aos="fade-left"
        data-aos-easing="ease-out"
        data-aos-duration="650"
        data-aos-delay="0" class="buttonsContainer">

            <button :class="{ 'active': activeButton === 'Flex N Gate' }" @click="activarBoton('Flex N Gate')">Flex N
                Gate</button>

            <!-- <button :class="{ 'active': activeButton === 'Prodomax' }" @click="activarBoton('Prodomax')">Prodomax</button>

            <button :class="{ 'active': activeButton === 'Centerline' }" @click="activarBoton('Centerline')">Centerline</button>

            <button :class="{ 'active': activeButton === 'Legacy' }" @click="activarBoton('Legacy')">Legacy</button> -->
        </div>

        <div class="itemProyecto">
            <div class="proyectoImg">

                <img
                
                data-aos="fade-right"
                data-aos-easing="ease-out"
                data-aos-duration="650"
                data-aos-delay="200"
                
                src="../../assets/FlexNGateImg1.png" alt="">

            </div>

            <div class="proyectoTexto">
                <h3
                data-aos="fade-left"
                data-aos-easing="ease-in-out"
                data-aos-duration="650"
                data-aos-delay="0"
                >    {{ nombreProyecto }}</h3>
                <h4
                data-aos="fade-left"
                data-aos-easing="ease-in-out"
                data-aos-duration="750"
                data-aos-delay="0"
                >    {{ subtituloProyecto }}</h4>
                <p
                data-aos="fade-left"
                data-aos-easing="ease-in-out"
                data-aos-duration="750"
                data-aos-delay="0"
                >     {{ descripcionProyecto }}</p>
            </div>
        </div>

        <a href="#proyectos"> Volver a proyectos</a>


    </section>
</template>

<script>
export default {
  data() {
    return {
      activeButton: 'Flex N Gate',
      nombreProyecto: 'Flex N Gate',
      subtituloProyecto: 'P758 Aumento de Volumen',
      descripcionProyecto: 'Este proyecto implicó un proceso integral de 4 líneas de robótica que abarca desde el análisis de los tiempos de ciclo, diseño mecánico y eléctrico, simulación robótica, mecanizado y pintura, montaje mecánico y eléctrico de herramientas hasta la instalación mecánica y eléctrica, puesta en marcha y puesta en marcha del equipo. El proyecto se ejecutó siguiendo una planificación detallada que cubría cada fase del proceso, garantizando una ejecución eficiente y coordinada. El proyecto hizo uso de tecnologías avanzadas, incluyendo MS Project, simulación de robots Roboguide, programación de PLC y robótica, diseño mecánico con SolidWorks y diseño eléctrico con AutoCAD Electrical. Este proyecto se completó con éxito siguiendo un cronograma bien definido.',
      imagenProyecto: '../../assets/FlexNGateImg1.png',
    };
  },
  methods: {
    activarBoton(nombreProyecto) {
      this.activeButton = nombreProyecto;
      // Aquí puedes actualizar los datos de la sección según el botón seleccionado
      if (nombreProyecto === 'Flex N Gate') {
        this.nombreProyecto = 'Flex N Gate';
        this.subtituloProyecto = 'P758 Aumento de Volumen';
        this.descripcionProyecto = 'Este proyecto implicó un proceso integral de 4 líneas de robótica que abarca desde el análisis de los tiempos de ciclo, diseño mecánico y eléctrico, simulación robótica, mecanizado y pintura, montaje mecánico y eléctrico de herramientas hasta la instalación mecánica y eléctrica, puesta en marcha y puesta en marcha del equipo. El proyecto se ejecutó siguiendo una planificación detallada que cubría cada fase del proceso, garantizando una ejecución eficiente y coordinada. El proyecto hizo uso de tecnologías avanzadas, incluyendo MS Project, simulación de robots Roboguide, programación de PLC y robótica, diseño mecánico con SolidWorks y diseño eléctrico con AutoCAD Electrical. Este proyecto se completó con éxito siguiendo un cronograma bien definido.',
        this.imagenProyecto = '../../assets/FlexNGateImg1.png';

      } else if (nombreProyecto === 'Prodomax') {
        this.nombreProyecto = 'Prodomax';
        this.subtituloProyecto = 'TextoMuyRandom';
        this.descripcionProyecto = 'This undertaking encompassed a comprehensive process involving four robotics lines. The process spanned from analyzing cycle times, mechanical and electrical design, robotic simulation, machining, and painting, to the mechanical and electrical assembly of tooling. It extended further to encompass mechanical and electrical installation, commissioning, and the initiation of equipment start-up.The project adhered to a meticulous plan that addressed each phase of the process, ensuring an efficient and well-coordinated execution. Advanced technologies, such as MS Project, Roboguide robot simulation, PLC and robotics programming, mechanical design using SolidWorks, and electrical design using AutoCAD Electrical, were employed throughout the project.Successfully, the project reached completion within the defined schedule.';
        this.imagenProyecto = '../../assets/ProdomaxImg1.png';

      } else if (nombreProyecto === 'Centerline') {
        this.nombreProyecto = 'Centerline';
        this.subtituloProyecto = 'TextoMuyRandom2';
        this.descripcionProyecto = 'This project was a multifaceted endeavor that engaged in a four-step process for robotics lines. This process entailed the analysis of cycle times, mechanical and electrical design, robotic simulation, machining, and painting. Additionally, it included the mechanical and electrical assembly of tooling, as well as the mechanical and electrical installation, commissioning, and initiation of equipment start-up.The successful execution of the project was underpinned by a detailed plan that meticulously covered each phase. Utilizing advanced technologies such as MS Project, Roboguide robot simulation, PLC and robotics programming, SolidWorks for mechanical design, and AutoCAD Electrical for electrical design, the project was completed within the stipulated schedule.';
        this.imagenProyecto = '../../assets/CenterlineImg1.png';
        
    } else if (nombreProyecto === 'Legacy') {
        this.nombreProyecto = 'Legacy';
        this.subtituloProyecto = 'TextoMuyRandom3';
        this.descripcionProyecto = 'An integral part of this project involved a four-phase process focused on robotics lines. The process initiated with the analysis of cycle times and progressed through mechanical and electrical design, robotic simulation, machining, and painting. Subsequently, it included the mechanical and electrical assembly of tooling, mechanical and electrical installation, commissioning, and equipment start-up.The projects success was ensured through meticulous planning, covering each phase of the process efficiently. Advanced technologies, such as MS Project, Roboguide robot simulation, PLC and robotics programming, SolidWorks for mechanical design, and AutoCAD Electrical for electrical design, played a crucial role in the projects completion within the specified timeline.';
        this.imagenProyecto = '../../assets/CenterlineImg1.png';
}
    },
  },
};
</script>
ad

<style scoped>
*{
    scroll-behavior: smooth;
}
section {
    overflow: hidden;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    padding: 2vw 15vw;
}

h2 {
    margin: 0 2vw;
    font-family: var(--MOSB_Light);
    font-weight: 300;
    color: var(--color-2);
    font-size: 5vw;
}

.miniLine {
    margin: 0 2vw;
    margin-top: 1vw;
    width: 2.6vw;
    border: solid 1px #06C5FF;
}

.buttonsContainer {
    display: flex;
    gap: 0.6vw;
    justify-content: flex-end;
}

.buttonsContainer button {
    color: #196BFE;
    cursor: pointer;
    border: 0.1vw solid #196BFE;
    background: none;
    padding: 1vw 2.5vw;
    font-family: var(--MOSB_Light);
    font-size: 0.9vw;
}

.active {
    color: var(--color-5) !important;
    border: 0.1vw solid #06C5FF !important;
    background: #06C5FF !important;
}

    /* Item Proyectos */

.itemProyecto{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28vw;
    gap: 2vw;
    margin: 3vw 0;
}

.proyectoImg img{
    object-fit: cover;
    width: 39vw;
    height: 27vw;
}

.proyectoTexto{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    

    width: 24vw;
    height: 27vw;
}
.proyectoTexto h3{
    font-family: var(--MOSB_Light);
    color: #196BFE;
    font-size: 1vw;
}
.proyectoTexto h4{
    font-size: 0.9vw;
    font-family: var(--MOSB_Light);
    color: #76E2FF;
    margin: 0;
    font-weight: 200;
}
.proyectoTexto p{ 
    line-height: 1.3vw;
    font-size: 0.8vw;
    font-family: var(--MOSB_Thin);
    margin: 0;
    color: #ADADAD;
    text-wrap:balance;
}
.proyectos a{
    display: none;
}


@media(max-width: 768px){
    section{
        padding: 12vw 12vw 0 12vw;
    }

    h2{
        font-family: var(--MOSB_Regular);
        font-size: 10vw;
        margin-bottom: 8vw;
    }
    .miniLine{
        width: 12vw;
        margin-bottom: 5vw ;
    }

    .itemProyecto{
        flex-direction: column;
        height: auto;
    }
    .buttonsContainer{
        flex-direction: column;
        gap: 2vw;
    }
    .buttonsContainer button{
        font-size: 3vw;
        padding: 3vw;
    }
    .proyectoTexto{
        width: auto;
        height: auto;
    }
    .proyectoImg img{
        margin-top: 15vw;
        object-fit: cover;
    width: 76vw;
    height: 41vw;
    }
    .proyectoTexto h3{
        line-height: 0;
        font-size: 6vw;
        margin: 4vw;
        text-align: center;
        transform: translateY(-55vw);
    }
    .proyectoTexto h4{
        font-size: 4vw;
    }
    .proyectoTexto p{
        margin-top: 3vw;
        font-family: var(--OpenSans);
        font-size: 4vw;
        line-height: 5vw;
    }

    .proyectos a{
        display:block;
        font-family: var(--MOSB_Regular);
        color: var(--color-2);
        text-align: center;
        padding: 5vw 0 8vw 0;
    }
}
</style>