<template>

    <div class="hero">
        <iframe class="web"
            src="https://www.youtube.com/embed/kmj6A-B3Jas?rel=0&amp;si=DN7ZcGhnDAfVhNzb&amp;controls=0&autoplay=1&mute=1&loop=1&playlist=kmj6A-B3Jas"
            title="YouTube video player" frameborder="0" allow="autoplay" allowfullscreen></iframe>
        <iframe class="movil"
            src="https://www.youtube.com/embed/R57h-c1foMA?rel=0&amp;si=DN7ZcGhnDAfVhNzb&amp;controls=0&autoplay=1&mute=1&loop=1&playlist=R57h-c1foMA"
            title="YouTube video player" frameborder="0" allow="autoplay" allowfullscreen></iframe>
        <img data-aos="fade-down" data-aos-easing="ease-in-out" data-aos-duration="700" src="../../assets/logonav.png"
            alt="">
        <a href="/blog-es" class="btn">
            <p>Más información sobre
                <span>Maquinaria Industrial</span>

            </p>
            <p class="decoAdd">
                Blog <img src="../../assets/arrow3.svg" alt="">
            </p>
        </a>

        <div class="separator"></div>

        <div class="heroContent">
            <h1 class="titulo" data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500"
                data-aos-delay="300">Maquinado industrial {{ ciudad }}</h1>
            <h2 data-aos="fade-right" data-aos-easing="ease-in-out" data-aos-duration="500" data-aos-delay="300">
                Proporcionando soluciones industriales <br> para <span class="accentColor"> hacer crecer tu negocio
                </span></h2>
            <a href="#form">
                <button id="btnenviarmensaje" data-aos="fade-right" data-aos-easing="ease-in-out"
                    data-aos-duration="700" data-aos-delay="500">
                    Enviar mensaje <img src="../../assets/arrow3.svg" alt="">
                </button>
            </a>
        </div>

        <div class="heroBrands">
            <h3>Clientes que han confiado en nosotros:</h3>
            <div class="heroBrandsContainer">

                <div data-aos="fade-up" data-aos-easing="ease-out" data-aos-duration="700" data-aos-delay="700"
                    class="Brand">
                    <img src="../../assets/logoLegacy.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>

                <div data-aos="fade-up" data-aos-easing="ease-out" data-aos-duration="700" data-aos-delay="800"
                    class="Brand">
                    <img src="../../assets/logoProdoMax.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>

                <div class="Brand" data-aos="fade-up" data-aos-easing="ease-out" data-aos-duration="700"
                    data-aos-delay="900">
                    <img src="../../assets/logoCenterLine.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>

                <div class="Brand" data-aos="fade-up" data-aos-easing="ease-out" data-aos-duration="700"
                    data-aos-delay="1100">
                    <img src="../../assets/logoFlexNGate.png" alt="">
                    <a href="#proyectos"><button>Ver Proyecto <img class="Btnimg" src="../../assets/arrow4.svg"
                                alt=""></button></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ciudad: {
            type: String,
            required: true,
        },
    },
    // ...
};
</script>

<style scoped>
a {
    text-decoration: none;
}

.btn {
    position: absolute;
    background: linear-gradient(90deg, #262FFD -16.53%, #0CA7FF 95.45%);    height: 10vw;
    width: 13VW;
    height: 10vw;
    top: -4vw;

    right: 17.5vw;
    border-radius: 0.5vw;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 2vw;
    clip-path: polygon(0 80%, 0 0, 100% 0, 100% 80%, 50% 100%);

    transition: all 300ms !important;
    animation: infinite call 4s;
}
@keyframes call{
    0%{
        transform: translateY(0vw);
        
    }
    45%{
        transform: translateY(0vw);
    }
    55%{
        transform: translateY(1vw);
    }
    65%{
        transform: translateY(0vw);

    }
    100%{
        transform: translateY(0vw);

    }
}

.btn p {
    text-align: center;
    margin: 0;
    color: white;
    font-family: var(--MOSB_Thin);
    transform: translateY(2vw);
    transition: all 300ms;
    font-size: 0.73vw;
}
.btn span{
    font-size: 0.8vw;

    font-family: var(--MOSB_Regular);

}
.decoAdd{
    height: 0;
    overflow: clip;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
    font-size: 1.3vw !important;

    transition: all 300ms;
    transform: translateY(0vw) !important;
    font-family: var(--MOSB_Regular) !important;
    

}
.decoAdd img{
    width: 3vw !important;
    height: 1vw !important;
}
.btn:hover{
    top: -1vw !important;
    animation: none;
    gap: 1vw;


}
.btn:hover p{
    transform: translateY(0vw);
}
.btn:hover .decoAdd{
    height: 2vw;
}

.hero {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
    background-size: cover;
    display: flex;
    padding: 4vw 10vw 0vw 10vw;
    flex-direction: column;
    align-items: center;
    gap: 7vw;
    align-self: stretch;
    overflow-x: clip;
}

.movil {
    position: relative;
    display: none;
}

.hero img {
    width: 14vw;
}

.web {
    display: block;
    transform: translateY(-11.5vw);
    z-index: -999;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 49.6vw;
    display: block;
}

.heroContent {
    font-family: var(--MOSB_Regular);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.6vw;
    flex: 1 0 0;
    align-self: stretch;

    color: white;
}

.heroContent h1 {
    font-weight: 400;
    font-size: 2vw;
    margin: 0;
}

.heroContent h2 {
    font-size: 2vw;
    margin: 0;
}

.heroContent .titulo {
    font-size: 3vw;
}

.accentColor {
    color: var(--color-6);
}

.heroContent button {
    background: -webkit-linear-gradient(0deg, #0CA7FF 1.66%, #0CA7FF 26.68%, #262FFD 100.08%) 0% 0% / 300% 300%;
    background-size: 200% auto;

    font-size: 0.9vw;
    color: white;
    border: none;
    font-weight: 400;
    font-family: var(--MOSB_Regular);
    padding: 1.5vw 4vw;
    cursor: pointer;

}

.heroContent button img {
    margin-left: 0.6vw;
    height: 0.5vw;
    width: auto;
}

.heroContent button:hover {
    animation: gradient_move 2s ease-in-out infinite;
    -webkit-animation: gradient_move 2s ease-in-out infinite;
}


.heroBrands {
    display: flex;
    padding: 0.5vw 3vw;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 4vw;
    align-self: stretch;
    flex-wrap: wrap;

    color: white;

}

.heroBrands h3 {
    font-size: 0.9vw;
    font-family: var(--MOSB_Regular);
    font-weight: 200;
}

.heroBrandsContainer {
    display: flex;
    align-items: flex-start;
    gap: 2vw;
}

.Brand {
    width: 9.5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
}

.Brand img {
    object-fit: contain;
    width: 8vw;
    height: 4vw;
    z-index: 10;
}

.Brand a {
    position: absolute;
    transform: translateY(2vw);
    opacity: 0;
    width: 9.5vw;
    height: 3vw;
    text-decoration: none;
    transition: all 0.5s ease-in-out;

}

.Brand a button {
    font-family: var(--MOSB_Thin);
    font-size: 0.8vw;
    display: flex;
    gap: 0.3vw;
    width: 9.5vw;
    cursor: pointer;
    justify-content: center;
    transform: translateY(-1.8vw);
    border: none;
    color: var(--color-5);
    align-items: flex-end;
    height: 6vw;
    background: linear-gradient(90deg, #262FFD -16.53%, #0CA7FF 95.45%);
    padding: 1vw 0;
    z-index: -2;
}

img.Btnimg {
    transform: translateY(1.6vw);
    width: 1.5vw;
}

.Brand:hover>a {
    opacity: 1;
}

.Brand:hover>.Brand a button {
    opacity: 1;
}

.separator {
    display: none;
}

@-webkit-keyframes gradient_move {
    0% {
        background-position: 0% 92%
    }

    70% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}

@keyframes gradient_move {
    0% {
        background-position: 0% 92%
    }

    70% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}

@media(max-width: 768px) {


    .web {
        position: relative;
        display: none;
    }

    .movil {
        display: block;
        transform: translateY(-25vw);
        z-index: -999;
        position: absolute;
        pointer-events: none;
        width: 100%;
        height: 182vw;
        display: block;
    }

    .hero {
        padding: 15vw 15vw 15vw 15vw;
    }

    .hero img {
        width: 50vw;
    }

    .heroContent {
        align-items: center;
    }

    .heroContent h2 {
        font-size: 6.5vw;
    }

    .heroBrands {
        display: none;
    }

    .heroContent button {
        font-family: var(--MOSB_Thin);
        font-size: 3.5vw;
        padding: 5vw 14.5vw;
        margin-top: 5vw;
    }

    .heroContent button img {
        margin-left: 1.6vw;
        height: 1.9vw;
        width: 10vw;
    }

    .separator {
        display: block;
        margin-top: 15vw;
    }

    .heroContent .titulo {
        font-size: 9vw;
    }
    .btn{
        width: 60VW;
        height: 18vw;
    }
    .btn:hover .decoAdd{
        height: 4vw;
    }
    .btn p{
        display: flex;
        flex-direction: column;
        font-size: 3vw;
    }
    .btn p span{
        font-size: 4vw;
    }
    .decoAdd{
        font-size: 3vw !important;
    }
}
</style>