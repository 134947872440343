<template>
    <div class="servicios">
        <hr class="solid">
        <img class="logobg" src="../../assets/footer/Logo_BG.png" alt="">
        <div class="MiniServ">
            <H2>
                ¿Qué otro tipo de <span>servicios <br> industriales </span> ofrecemos {{ ciudad }} ?
            </H2>

            <h3><span>Mantenimiento industrial {{ ciudad }}</span></h3>
            <p>
                Contamos con un conjunto de procesos en los cuales nos encargamos de llevar a cabo revisiones detalladas
                de
                la maquinaria, instalación, equipo y cualquier elemento que tenga relación mantenimiento de equipos
                industriales.
            </p>
            <h3><span>Reparación de maquinaria pesada {{ ciudad }}</span></h3>
            <p>
                En nuestro taller de reparación de maquinaria industrial nos especializamos en mantener y arreglar
                maquinaria pesada; en pocas palabras, mantener saludables cualquier tipo de herramienta que utilices en
                tu
                empresa. Garantizamos una inspección a fondo del estado de tu máquina, pues contamos con las refacciones
                compatibles para cualquier tipo de equipo pesado.
            </p>


        </div>

        <H3>
            Ofrecemos nuestros servicios de <span>manufactura inteligente </span> y <br> <span>mantenimiento
                industrial</span> {{ ciudad }} para industrias como:
        </H3>


        <div class="item-servicio" data-aos="fade-right" data-aos-easing="ease-out" data-aos-duration="600"
            data-aos-delay="150">
            <div class="item-servicio-text">
                <h2>Agricultura</h2>
                <h3>Servicios especializados que abarcan la <span class="bold"> reparación de equipos de
                        bombeo
                    </span>, la <span class="bold">fabricación y
                        montaje de estructuras</span>, <span class="bold"> pailería en general </span>y la <span
                        class="bold"> producción de tanques </span>. Garantizamos soluciones
                    eficientes y de alta calidad para satisfacer las necesidades de nuestros clientes.</h3>
            </div>

            <agile :navButtons="false" :dots="false" :infinite="false" class="item-servicio-swiper">
                <img src="../../assets/ServicioAgricultura.png" alt="">
                <!-- <h4><span>1</span> de 4</h4> -->
            </agile>
        </div>

        <div class="item-servicio" data-aos="fade-left" data-aos-easing="ease-out" data-aos-duration="600"
            data-aos-delay="150">
            <div class="item-servicio-text">
                <h2>Minería</h2>
                <h3>Nos dedicamos desde el <span class="bold">diseño de piezas mecánicas</span> hasta la <span
                        class="bold">reparación y sustitución de componentes</span> afectados por la abrasión y el
                    desgaste,
                    como bujes y bridas. También contamos con la <span class="bold">fabricación y montaje de
                        estructuras</span> que es vital para cualquier proyecto.</h3>
            </div>

            <agile :navButtons="false" :dots="false" :infinite="false" class="item-servicio-swiper">
                <img src="../../assets/ServicioMineria.png" alt="">
                <!-- <h4><span>1</span> de 4</h4> -->
            </agile>
        </div>

        <div class="item-servicio" data-aos="fade-right" data-aos-easing="ease-out" data-aos-duration="600"
            data-aos-delay="150">
            <div class="item-servicio-text">
                <h2>Industria <br>
                    <span class="accent">Automotriz</span>
                </h2>
                <h3>Destacamos en la industria automotriz ofreciendo servicios integrales como el <span
                        class="bold">diseño
                        de herramentales, fixtures, sistemas de manejo de materiales</span> y la <span
                        class="bold">integración de sistemas</span>. Brindamos soluciones precisas y eficientes para
                    optimizar los procesos y garantizar la excelencia en la fabricación y ensamblaje de componentes
                    automotrices.</h3>
            </div>

            <agile :navButtons="false" :dots="false" :infinite="false" class="item-servicio-swiper">
                <img src="../../assets/ServicioAutomotriz.png" alt="">
                <!-- <h4><span>1</span> de 4</h4> -->
            </agile>
        </div>

        <div class="item-servicio" data-aos="fade-left" data-aos-easing="ease-out" data-aos-duration="600"
            data-aos-delay="150">
            <div class="item-servicio-text">
                <h2>Mecánica <br>
                    <span class="accent">de Precisión</span>
                </h2>
                <h3>Fabricación de <span class="bold">alta precisión industrial</span> ; con la cual apoyamos a crear
                    <span class="bold">turbinas, prótesis médicas, robótica, componentes aeroespaciales</span> entre
                    otros
                    sistemas <span class="bold">maquinados</span> de alta precisión industrial.
                </h3>
            </div>

            <agile :navButtons="false" :dots="false" :infinite="false" class="item-servicio-swiper">
                <img src="../../assets/ServicioMecanica.png" alt="">
                <!-- <h4><span>1</span> de 4</h4> -->
            </agile>
        </div>



    </div>
</template>

<script>
export default {
    props: {
        ciudad: {
            type: String,
            required: true,
        },
    },
    // ...
};
</script>


<style scoped>
.solid {
    height: 0.02vw;
    color: var(--color-4);
    width: 25vw;
    margin-top: 5vw;
    margin-bottom: 1vw;
    opacity: 40%;
}

.servicios {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
    margin-bottom: 3vw;
}

.servicios .info {
    width: 58vw;
    text-align: center;
    font-weight: 400;
    font-size: 0.79vw;
    color: var(--color-4);
}

.MiniServ {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.MiniServ>h2 {
    font-size: 2.5vw;
    margin: auto;
    text-align: center;
    color: var(--color-3);
    margin-bottom: 2.5vw;
    font-family: var(--MOSB_Light);


}


.MiniServ>h2 span {
    color: var(--color-2);
}

.MiniServ>h3 {
    font-size: 1.5vw;
    margin: auto;
    text-align: center;
    color: var(--color-3);
    font-family: var(--MOSB_Thin);


}

.MiniServ>h3 span {
    color: var(--color-2);
}

.MiniServ>p {
    width: 63vw;
    margin: auto;
    margin-bottom: 2vw;
}

.servicios>h3 {
    font-size: 1.8vw;
    margin: auto;
    text-align: center;
    color: var(--color-3);
    font-family: var(--MOSB_Light);


}

.servicios>h3 span {
    color: var(--color-2);
}


.bold {
    font-weight: 600;
}

.item-servicio {
    gap: 3vw;
    display: flex;
    align-items: center;
}

.item-servicio h2 {
    font-size: 2vw;
    font-family: var(--MOSB_Light);
    color: var(--color-2);
    margin: 0;
    font-weight: 800;
}

.item-servicio h3 {
    font-size: 0.8vw;
    font-weight: 400;
    color: var(--color-4);
    margin: 0 0 3vw 5vw;
}

.item-servicio-text {
    height: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4vw;
    width: 30vw;
}

.item-servicio-swiper {
    width: 30vw;
    display: flex;
    height: 25vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.item-servicio-swiper img {
    object-fit: cover;
    height: 20vw;
    width: 28vw;
}

.item-servicio-swiper h4 {
    font-size: 0.8vw;
}

.item-servicio:nth-child(odd) {
    flex-direction: row-reverse;
}

.accent {
    color: var(--color-6);
}

.logobg {
    width: 36vw;
    transform: translateX(25.98vw) scale(1.3);
    position: absolute;
    z-index: -9;
    opacity: 0.5;
}

:deep(.agile__actions) {
    transform: translateY(-1vw);
    gap: 0.2vw;
}

:deep(.agile__dots) {
    gap: 0.2vw;
}

:deep(.agile__dot button) {
    font-family: var(--OpenSans);
    width: 0.8vw;
    background: var(--color-4);
    opacity: .4;
    border: none;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

:deep(.agile__dot--current) {
    border-radius: 50%;
    opacity: .8;
    background: var(--color-4);
}

:deep(.agile__nav-button) {
    background: transparent;
    border: none;
    font-size: 1.5vw;
}

@media(max-width: 769px) {
    .servicios {
        gap: 8vw;
    }

    .solid {
        width: 60vw;
        opacity: 50%;
    }

    .logobg {
        display: none;
    }

    .servicios .info {
        text-align: left;
        font-size: 4vw;
        width: 80vw;
        margin-bottom: 6vw;
    }

    .item-servicio {
        flex-direction: column !important;
    }

    .item-servicio-text {
        width: 80vw;
        padding: 0;
        height: auto;
    }

    .item-servicio-text h2 {
        text-align: center;
        font-family: var(--MOSB_Light);
        font-size: 10vw
    }

    .item-servicio-text h3 {
        font-size: 4vw;
    }

    .item-servicio h3 {
        margin: 0;
    }

    .item-servicio-swiper {
        width: 80vw;
        display: flex;
        height: 50vw;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .item-servicio-swiper img {
        object-fit: cover;
        width: 80vw;
        height: 40vw;
    }

    :deep(.agile__actions) {
        transform: translateY(-1vw);
        gap: 2vw;
    }

    :deep(.agile__dots) {
        gap: 1.5vw;
    }

    :deep(.agile__dot button) {
        background: var(--color-4);
        opacity: .4;
        border: none;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
    }

    :deep(.agile__dot--current) {
        border-radius: 50%;
        opacity: .8;
        background: var(--color-4);
    }

    :deep(.agile__nav-button) {
        transform: translateY(-1.5vw);
        background: transparent;
        border: none;
        font-size: 7vw;
    }

    :deep(.agile__dot button) {
        width: 3vw;
    }

    .MiniServ>h2 {
        font-size: 5.5vw;
        margin-bottom: 8vw;
    }
    .MiniServ>h3 {
        font-size: 4vw;
    }
    .MiniServ p{
        margin-bottom: 8vw;
        font-size: 4vw;
        width: 80vw;
    }
    .servicios>h3{
        font-size: 4vw;
    }
    .servicios>h3 br{
        display: none;
    }
}
</style>