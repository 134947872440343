<template>
    <div class="sliderContainer">

        <p class="info" data-aos="fade-down" data-aos-easing="ease-out" data-aos-duration="600" data-aos-delay="0">
            El objetivo de nuestro taller de torno y fresadora es poder desarrollar maquinados de precisión, así como
            fixtures que te ayudarán a generar resultados positivos y resolver problemas industriales con un sistema
            automatizado que agilice los procesos en tu empresa {{ ciudad }}.
        </p>
        <div class="titulo">
            <h3>Nuestros servicios de</h3>
            <h2>Maquinado</h2>
        </div>

        <div class="slide">


            <div class="slide-item" data-aos="fade-down" data-aos-easing="ease-out" data-aos-duration="600"
                data-aos-delay="50">
                <img src="../../assets/Slider Centro de Maquinado.png" alt="">
                <h4>Maquinados CNC <span class="noBold">(fresadora)</span> Haas VF-4SS</h4>
                <p>En el proceso de mecanizado CNC controlamos máquinas con ayuda de un ordenador que nos permite
                    maquinar piezas exactas y terminadas a la petición de materiales y diseño que hicimos junto con el
                    cliente. </p>
            </div>

            <div class="slide-item" data-aos="fade-down" data-aos-easing="ease-out" data-aos-duration="600"
                data-aos-delay="150">
                <img src="../../assets/Slider FresadoraAres.png" alt="">
                <h4>Fresadora Industrial Ares X6323A 9" x49" </h4>
                <p>Es un tipo de herramienta de maquinado que permite labrar canales y huecos con distintos perfiles;
                    con ayuda de la fresadora industrial también podemos perforar y canalizar cualquier pieza mecánica
                    que requiera la industria. </p>
            </div>

            <div class="slide-item" data-aos="fade-down" data-aos-easing="ease-out" data-aos-duration="600"
                data-aos-delay="350">
                <img src="../../assets/Slider CierraCinta.png" alt="">
                <h4>Corte en sierra cinta SC-712 Titanium</h4>
                <p>Con este servicio industrial somos capaces de crear piezas maquinadas talladas y formadas en forma de
                    curva con el material que el cliente requiera; así mismo, nos permite hacer recortes sobre madera y
                    todo tipo de corte transversal. </p>
            </div>

            <div class="slide-item" data-aos="fade-down" data-aos-easing="ease-out" data-aos-duration="600"
                data-aos-delay="550">
                <img src="../../assets/Slider Soldadora Maraga.png" alt="">
                <h4>HyperFill® Reveal™ Power Feed® 84 Ready-Pak® individual</h4>
                <p>Contamos también con un servicio de soldadura como opción para obtener una mejor resistencia ante
                    corrosiones o rupturas de material. Creamos soldaduras uniformes y limpias, trazadas por nuestros
                    técnicos especializados en material de acero o aluminio junto con nuestras máquinas CNC. </p>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    props: {
        ciudad: {
            type: String,
            required: true,
        },
    },
    // ...
};
</script>


<style scoped>
.sliderContainer {
    height: 50vw;

}

.titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -3;
}

.titulo h3 {
    font-family: var(--MOSB_Regular);
    color: var(--color-2);
    font-size: 1.5vw;
    transform: translateY(3vw);
    line-height: 0vw;
}

.titulo h2 {
    line-height: 0vw;
    font-family: var(--MOSB_Light);
    color: var(--color-6);
    font-size: 7vw;
}

.slide {
    display: flex;
    padding: 0 6vw;
    height: 20vw;
    transform: translateY(-4.5vw);
    justify-content: center;

}

.slide-item {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    text-align: center;

}

.slide-item img {
    object-fit: contain;
    aspect-ratio: 1/1;
    height: 20vw;
    transition: transform 0.2s;
}

.slide-item img:hover {
    transform: scale(1.1);
}

.slide-item h4 {
    color: var(--color-3);
    font-size: 0.8vw;
    text-wrap: balance;
    font-family: var(--MOSB_Light);
    padding: 0vw 2vw;
}

.slide-item p {
    font-size: 0.8vw;
    line-height: 1.2vw;
    width: 15vw;
    margin: auto;
    color: var(--color-4);

}

.noBold {
    font-family: var(--MOSB_Thin);
}

.info {
    margin: auto;
    width: 50%;
    text-align: center;
    margin-top: 5vw;
    color: var(--color-4);
}


:deep(.agile__actions) {

    transform: translateY(-15vw);
}

:deep(.agile__actions button) {
    background: transparent;
    border: none;
    color: aqua;
}

@media(max-width: 769px) {
    .titulo {
        margin-top: 12vw;
    }

    .titulo h3 {
        font-family: var(--MOSB_Light);
        font-size: 5vw;
        font-weight: 200;
    }

    .titulo h2 {
        font-size: 12vw;
        margin: 7vw;
    }

    .sliderContainer {
        height: 424vw;
    }

    .slide {
        flex-direction: column;
        align-items: center;
        height: auto;
        transform: translateY(0vw);
        gap: 4vw;
    }

    .slide-item {
        width: 60vw;
    }

    .slide-item img {
        height: 40vw;
    }

    .slide-item h4 {
        margin: 0;
        font-size: 3vw;
    }

    .slide-item p {
        font-size: 3.2vw;
        width: 100%;
        line-height: 4.2vw;
    }

    .info {
        margin-top: 17vw;
    }
}
</style>
