<template>
    <section id="form">
        <div class="tittle">
            <h4 data-aos="fade-up" data-aos-easing="ease-out" data-aos-duration="650" data-aos-delay="0">contáctanos
            </h4>
            <h2 data-aos="zoom-in-up" data-aos-easing="ease-out" data-aos-duration="650" data-aos-delay="0">Estamos
                listos
                <span>para ayudarte</span>
            </h2>
        </div>
        <hr>

        <form class="container formulario" @submit.prevent="enviarMail(nombre, empresa, correo, ciudad, mensaje)"
            :class="{ especial: this.$route.name != 'inicio' && this.$route.name != 'contacto' }">
            <input type="text" placeholder="Nombre" id="nombre" v-model="nombre">
            <input type="text" placeholder="Empresa" id="empresa" v-model="empresa">
            <input type="email" placeholder="Correo electrónico" id="correo" v-model="correo">
            <input type="text" placeholder="Ciudad" id="ciudad" v-model="ciudad">
            <textarea id="mensaje" placeholder="Mensaje" v-model="mensaje"></textarea>

            <fieldset class="terminos">
                <button id="check" @click.prevent="checkValue = !checkValue; check()"
                    aria-label="Clickeame para aceptar términos y condiciones">
                    <div></div>
                </button>
                <label for="check">He leído el <a href="/aviso-de-privacidad" class="aviso">Aviso de Privacidad</a>
                    antes de
                    enviar el
                    formulario.</label>
            </fieldset>

            <button type="submit" id="btnenviarform" class="button">
                <p>Enviar mensaje</p>
                <img src="../../assets/arrow.svg" alt="Flecha">
            </button>

            <div class="status__container">
                <p class="msg_success" v-if="status == 'success' && message != ''">{{ message }}</p>
                <p class="msg_error" v-if="status == 'error' && message != ''">{{ message }}</p>
            </div>
        </form>
    </section>
</template>
<script>

export default {
    data() {
        return {
            nombre: "",
            correo: "",
            empresa: "",
            mensaje: "",
            ciudad: "",

            checkValue: false,

            message: '',
            status: '',
        }
    },

    methods: {

        check() {
            const cuadro = document.querySelector('#check div');

            if (this.checkValue) {
                cuadro.classList.add('confirm')
            } else {
                cuadro.classList.remove('confirm')
            }
        },


        enviarMail: async function (nombre, empresa, correo, ciudad, mensaje) {
            this.message = "";
            this.status = "";
            let filter =
                /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

            if (this.checkValue) {
                if (this.nombre == undefined || this.nombre == "" || this.nombre == null) {
                    this.message = "Campos no válidos.";
                    this.status = "error";
                    this.borderError(0)
                }

                if (empresa == undefined || empresa == "" || empresa == null) {
                    this.message = "Campos no válidos";
                    this.status = "error";
                    this.borderError(2)
                }

                if (!filter.test(correo)) {
                    this.message = "Campos no válidos";
                    this.status = "error";
                    this.borderError(3)
                }

                if (correo == undefined || correo == "" || correo == null) {
                    this.message = "Campos no válidos";
                    this.status = "error";
                    this.borderError(3)
                }


                if (ciudad == undefined || ciudad == "" || ciudad == null) {
                    this.message = "Campos no válidos";
                    this.status = "error";
                    this.borderError(4)
                }

                if (mensaje == undefined || mensaje == "" || mensaje == null) {
                    this.message = "Campos no válidos";
                    this.status = "error";
                    this.borderError(5)
                }

                if (this.status != 'error') {



                    let data = {
                        name: nombre,
                        city: ciudad,
                        email: correo,
                        empresa, empresa,
                        mensaje: mensaje,
                    };


                    let response = await this.$store.dispatch(
                        "admin/enviarMailLanding",
                        data
                    );



                    if (response.status == "success") {
                        this.status = "success";
                        this.message = "Mensaje enviado con exito.";
                        this.nombre = "";
                        this.ciudad = "";
                        this.correo = "";
                        this.empresa = "";
                        this.mensaje = "";
                        this.delStatus();
                    } else {
                        this.status = "error";
                        this.message =
                            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
                        this.delStatus();
                    }
                }
            } else {
                this.status = "error";
                this.message = "No has aceptado los términos y condiciones";
                this.delStatus();
            }

        },

        delStatus: function () {
            setTimeout(() => this.delMsgs(), 3000);
        },

        delMsgs: function () {
            this.status = "";
            this.message = "";
        },
    },
}
</script>
<style scoped>
*,
*::before,
*::after {
    box-sizing: border-box;
}


section {
    margin: 5vw 0;
}

h4 {
    text-align: center;
    color: var(--color-4);
    font-size: 0.45vw;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.3vw;
}

h2 {
    font-family: var(--MOSB_Light);
    font-weight: 600;
    font-size: 2vw;
    text-align: center;
    color: var(--color-2);
}

h2 span {
    color: var(--color-1);
}

hr {
    width: 2vw;
    border: solid 0.1vw #06C5FF;
    margin-top: 3vw;
    margin-bottom: 4vw;
}

.container {
    display: grid;
    justify-content: center;
    position: relative;
}

input,
textarea {
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;

    color: #004AA4;

    font-style: normal;
    font-weight: 800;
}

input::placeholder,
textarea::placeholder {
    color: #A6A6A6;
    font-style: italic;
    font-weight: 400;
}

textarea {
    resize: none;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
}

button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}

#check {
    display: flex;
    justify-content: center;
    align-items: center;
}

p {
    margin: 0;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.button:hover {
    animation: gradient_move 2s ease-in-out infinite;
    -webkit-animation: gradient_move 2s ease-in-out infinite;
}

.button p {
    color: #FFF;

    font-style: normal;
    font-weight: 500;
}

.confirm {
    background-color: #196BFE;
}

label {
    color: #6C6C6C;

    font-style: normal;
    font-weight: 400;
}

label a {
    color: #196BFE;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.wrapper {
    position: relative;
}

.wrapper>button {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
}

ul button {
    color: #004AA4;

    font-style: normal;
    font-weight: 400;
    width: 100%;
}

.no-clicks {
    pointer-events: none;
}

.lista {
    position: absolute;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.6s ease-in;
    position: relative;
    z-index: 3;
}

.border__error,
.wrapper:has(.border__error) .lista__despliegue--borde {
    border-color: #E42828 !important;
}

.status__container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: -2vw;
}

.status__container p {
    color: #FEF9FF;
    text-align: center;
    font-style: normal;
    font-weight: 400;
}

.msg_success {
    background: linear-gradient(0deg, #0CA7FF 1.66%, #0CA7FF 26.68%, #262FFD 100.08%) 0% 0% / 300% 300%;
}

.msg_error {
    background-color: #E42828;
}

@media (max-width: 768px) {
    .container {
        margin: 5vw;
        row-gap: 5.555555555555555VW;
    }

    .tittle {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h4 {
        text-align: center;
        color: var(--color-4);
        font-size: 3vw;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1vw;
    }

    h2 {
        width: 60vw;
        font-family: var(--MOSB_Light);
        font-weight: 600;
        font-size: 7vw;
        text-align: center;
        color: var(--color-2);
    }

    hr {
        width: 10vw;
        border: solid 0.1vw #06C5FF;
        margin-top: 3vw;
        margin-bottom: 4vw;
    }

    input {
        height: 14.734299516908212VW;
        padding: 4.3478260869565215VW 9.420289855072465VW;
        border: 0.24154589371980675VW solid #1969fe7d;

        font-size: 3.3816425120772946VW;
        line-height: 100%;
        width: 100%;
    }

    .wrapper {
        height: 14.734299516908212VW;
    }

    .wrapper img {
        width: 2.657004830917874VW;
        height: 1.4492753623188406VW;
        margin: 7.246376811594203VW 7.729468599033816VW 0 auto;
    }

    .lista {
        border-bottom-left-radius: 1.2077294685990339VW;
        border-bottom-right-radius: 1.2077294685990339VW;
        border: 0.24154589371980675VW solid #196BFE;
        border-top: 0;
        border-bottom: 0;
        margin-top: -0.966183574879227VW;
        background-color: #E7E1D9;
    }

    .especial .lista {
        background-color: #F7F5F0;
    }

    .lista__despliegue {
        max-height: 45.71980676328503VW !important;
    }

    .lista__despliegue--borde {
        border-bottom: 0.24154589371980675VW solid #1969fe7d;
    }

    ul {
        padding: 3.864734299516908VW 0;
        row-gap: 0.8854166666666666VW;
    }

    ul button {
        padding: 0 9.420289855072465VW;
        text-align: start;
        font-size: 3.3816425120772946VW;
        line-height: normal;
    }

    textarea {
        height: 36.473429951690825VW;
        border: 0.24154589371980675VW solid #1969fe7d;
        padding: 4.3478260869565215VW 9.420289855072465VW;
    }

    .terminos {
        column-gap: 3.864734299516908VW;
        align-items: baseline;
        margin-top: 4.3478260869565215VW;
    }

    #check {
        border: 0.24154589371980675VW solid #1969fe7d;
        aspect-ratio: 1;
        height: 5.555555555555555VW;
    }

    #check div {
        width: 3.6231884057971016VW;
        height: 3.6231884057971016VW;
    }

    label {
        font-family: var(--MOSB_Light);
        color: #004AA4;
        font-size: 3.3816425120772946VW;
        line-height: 174.187%;
    }

    label a {
        color: #06C5FF;
    }

    .button {
        margin: 2.657004830917874VW auto 0;
        width: 57.48792270531401VW;
        height: 12.560386473429952VW;
        column-gap: 4.830917874396135VW;
        background: -webkit-linear-gradient(0deg, #0CA7FF 1.66%, #0CA7FF 26.68%, #262FFD 100.08%) 0% 0% / 300% 300%;
    }

    .button p {
        font-family: var(--MOSB_Light);
        font-size: 3.864734299516908VW;
        line-height: normal;
    }

    .button img {
        width: 6.038647342995169VW;
        height: auto;
    }
}

@media (min-width: 768px) {
    .container {
        grid-template-columns: repeat(2, 18.958333333333332VW);
        gap: 1.0416666666666665VW 1.1979166666666667VW;
    }

    #name,
    #mensaje {
        grid-column: 1 / -1;
    }

    .terminos {
        grid-column: span 2;
    }

    input {
        height: 3.177083333333333VW;
        padding: 0.625VW 2.03125VW;
        border: 0.052083333333333336VW solid #1969fe6c;

        font-size: 0.7291666666666666VW;
        line-height: 100%;
        width: 100%;
    }

    .wrapper {
        height: 3.177083333333333VW;
    }

    .wrapper>button {
        cursor: pointer;
    }

    .wrapper img {
        width: 0.5729166666666666VW;
        height: 0.3125VW;
        margin: 1.5625VW 1.6666666666666667VW 0 auto;
    }

    .lista {
        border-bottom-left-radius: 0.26041666666666663VW;
        border-bottom-right-radius: 0.26041666666666663VW;
        border: 0.052083333333333336VW solid #004AA4;
        border-top: 0;
        border-bottom: 0;
        margin-top: -0.20833333333333334VW;
        background-color: #F1EDE7;
    }

    .especial .lista {
        background-color: #FBFAF7 !important;
    }

    .lista__despliegue {
        max-height: 15.633333VW !important;
    }

    .lista__despliegue--borde {
        border-bottom: 0.052083333333333336VW solid #004AA4;
    }

    ul {
        padding: 1.4583333333333333VW 0;
        row-gap: 0.8854166666666666VW;
    }

    ul button {
        padding: 0 2.03125VW;
        text-align: start;
        font-size: 0.7291666666666666VW;
        line-height: normal;
        cursor: pointer;
    }

    textarea {
        height: 7.864583333333333VW;

        border: 0.052083333333333336VW solid #1969fe6c;
        padding: 1.1458333333333333VW 2.03125VW;

        font-size: 0.7291666666666666VW;
        line-height: 100%;
    }

    input::placeholder,
    textarea::placeholder {
        font-size: 0.7291666666666666VW;
        line-height: 100%;
    }

    .terminos {
        column-gap: 0.37239583333333337VW;
    }

    #check {

        border: 0.052083333333333336VW solid #004AA4;
        width: 0.8333333333333334VW;
        height: 0.8333333333333334VW;
        cursor: pointer;
    }

    #check div {
        width: 0.5208333333333333VW;
        height: 0.5208333333333333VW;
    }


    label {
        font-size: 0.7291666666666666VW;
        line-height: 174.187%;
    }

    .button {
        font-family: var(--MOSB_Light);
        grid-column: 2 / 3;
        margin: -1.0416666666666665VW 0 0 auto;
        width: 13.395833333333334VW;
        height: 2.7083333333333335VW;
        column-gap: 1.0416666666666665VW;
        background: -webkit-linear-gradient(0deg, #0CA7FF 1.66%, #0CA7FF 26.68%, #262FFD 100.08%) 0% 0% / 300% 300%;
        cursor: pointer;
    }

    .button p {
        font-size: 0.8333333333333334VW;
        line-height: normal;
    }

    .button img {
        width: 2.3020833333333335VW;
        height: auto;
    }


    .status__container p {
        padding: 0.15625VW 0.5208333333333333VW;
        border-radius: 3.125VW;
        font-size: 0.7291666666666666VW;
        line-height: normal;
    }
}

@-webkit-keyframes gradient_move {
    0% {
        background-position: 0% 92%
    }

    70% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}

@keyframes gradient_move {
    0% {
        background-position: 0% 92%
    }

    70% {
        background-position: 100% 9%
    }

    100% {
        background-position: 0% 92%
    }
}
</style>